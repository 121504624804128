import React from 'react';
import PrivateRoute from 'components/PrivateRoute';
import { Components } from 'sitedata';
import IndexLayout from 'layouts';
import ProfilePage from 'views/ProfilePage/ProfilePage';

const Profile = () => (
  <PrivateRoute>
    <IndexLayout>
      <Components.Header hasBorder />
      <ProfilePage />
      <Components.Footer />
    </IndexLayout>
  </PrivateRoute>
);

export default Profile;
