import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import notify from 'notify';

import { tryGetFirstError } from 'utils/requests';
import Button from 'components/Button';
import Input from 'components/Input';
import { updatePassword } from 'api/users';
import ProfileContext from './context';

interface PasswordSettingsProps {
  hideCurrentPassword?: boolean;
}

const PasswordSettings: React.FC<PasswordSettingsProps> = ({ hideCurrentPassword }) => {
  const { setProfile } = useContext(ProfileContext);
  const { register, errors, getValues, handleSubmit, reset } = useForm({
    mode: 'onChange'
  });

  const submit = async values => {
    try {
      await updatePassword({ password: values.newPassword, oldPassword: values.currentPassword });
      reset();
      notify('Password updated');
      setProfile(profile => ({ ...profile!, passwordSet: true }));
    } catch (err) {
      reset();
      notify(tryGetFirstError(err) || err.response);
    }
  };

  const minLength = {
    value: 8,
    message: 'Password too short, at least 8 symbols'
  };

  return (
    <div className="profile-section profile-password">
      <h3 className="profile-section__title">Change Password</h3>
      <form onSubmit={handleSubmit(submit)}>
        {!hideCurrentPassword && (
          <Input
            ref={register({
              required: 'Required',
              minLength
            })}
            name="currentPassword"
            label="Current Password"
            placeholder="Current Password"
            type="password"
            error={errors.currentPassword}
            className="profile-section__input"
          />
        )}
        <Input
          ref={register({
            required: 'Required',
            minLength
          })}
          name="newPassword"
          label="New Password"
          placeholder="New Password"
          type="password"
          error={errors.newPassword}
          className="profile-section__input"
        />
        <Input
          ref={register({
            required: 'Required',
            minLength,
            validate: value => value === getValues('newPassword') || `Passwords don't match`
          })}
          name="confirmPassword"
          label="Confirm Password"
          placeholder="Confirm New Password"
          type="password"
          error={errors.confirmPassword}
          className="profile-section__input"
        />
        <Button primary type="submit" className="profile-section__btn">
          Save
        </Button>
      </form>
    </div>
  );
};

export default PasswordSettings;
